import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import useSetUUID from "./utils/useSetUUID";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

const ApolloContainer = ({ children }) => {
  const setUuid = useSetUUID();
  const [cookies] = useCookies();
  const token = cookies._graphql_token;
  const uri = "/api/graphql/";
  const httpLink = createUploadLink({
    uri,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  useEffect(() => {
    setUuid({ userId: null });
  });

  const client = new ApolloClient({
    link: httpLink,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            donations: relayStylePagination([
              "kind",
              "searchTerm",
              "donorId",
              "teamId",
              "campaignId",
              "sortBy",
            ]),
            donors: relayStylePagination([
              "campaignId",
              "teamId",
              "searchTerm",
              "sortBy",
            ]),
            campaigns: relayStylePagination([
              "teamId",
              "searchTerm",
              "sortBy",
              "archived",
            ]),
            stripeSubscriptions: relayStylePagination([
              "campaignId",
              "teamId",
              "donorId",
            ]),
            donorTickets: relayStylePagination([
              "searchTerm",
              "donationId",
              "teamId",
              "campaignIds",
              "sortBy",
            ]),
            donationReceipts: relayStylePagination([
              "campaignId",
              "teamId",
              "searchTerm",
              "sortBy",
            ]),
            publicComments: relayStylePagination([
              "campaignId",
            ])
          },
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloContainer;
